<template>
  <div v-if="orders" class="custom-table">
    <table-actions
      @onSearchInput="inputChange"
      @perPageChange="perPageChange"
      :defaultPerPage="perPage"
    />

    <table-lite
      :is-slot-mode="true"
      :is-loading="isLoading"
      :columns="columns"
      :rows="orders"
      :sortable="{}"
      @is-finished="isLoading = false"
    >
      <template v-slot:id="data">
        <a :href="$helper.getEditUrl('orders', data.value.id)" class="link">{{ data.value.id }}</a>
      </template>
      <template v-slot:createdAt="data">
        {{ $helper.formatDate(data.value.createdAt) }} {{ $helper.formatTime(data.value.createdAt) }}
      </template>
      <template v-slot:updatedAt="data">
        {{ $helper.formatDate(data.value.updatedAt) }} {{ $helper.formatTime(data.value.updatedAt) }}
      </template>
      <template v-slot:quantity="data">
          {{ data.value.itemQuantity }}
      </template>
      <template v-slot:status="data">
          <b-badge :variant="$helper.getOrderStatusBadgeClass(data.value.status)">
              {{ $helper.getEnumTranslation("ORDER_STATUS", data.value.status, $i18n.locale) }}
          </b-badge>
      </template>
      <template v-slot:total="data">
        {{ $helper.getOrderTotal(data.value.items) }} EUR
      </template>

    </table-lite>

    <table-pagination
      v-if="pages"
      v-model="currentPage"
      :total="pages"
      :per-page="perPage"
      @input="onChangePage"
    />
  </div>
</template>

<script>
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
  components: {
    TableActions,
    TableLite,
    TablePagination
  },
  props: {
    itemObj: { type: Object, default() {} },
  },
  data() {
    return {
      item: this.itemObj,
      isLoading: false,
      orders: null,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      columns: [
        {
          label: this.$t("forms.id"),
          field: "id",
          sortable: false,
        },
        {
          label: this.$t("forms.quantity"),
          field: "quantity",
          sortable: false,
        },
        {
          label: this.$t("forms.status"),
          field: "status",
          sortable: false,
        },
        {
          label: this.$t("order.total"),
          field: "total",
          sortable: false,
        },
        {
            label: this.$t("forms.createdAt"),
            field: "createdAt",
            sortable: false,
        },
        {
            label: this.$t("forms.updatedAt"),
            field: "updatedAt",
            sortable: false,
        },
      ],
    };
  },
  mixins: [ListUtils, ResourceUtils],
  created() {
    this.loadOrders()
  },
  methods: {
    loadOrders() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
        "order[createdAt]": "desc",
      };
      this.$Items
        .getResourceByUrl({ url: `${this.item["@id"]}/orders`, params })
        .then((response) => {
          this.pages = response.data["hydra:totalItems"]
          this.orders = response.data["hydra:member"]

          this.orders.forEach((order) => {
              order.itemQuantity = 0
              order.items.forEach((orderItem) => {
                  if (orderItem.item['@id'] === this.item['@id']) {
                      order.itemQuantity += orderItem.quantity
                  }
              })
          })

        });
    },
    onChangePage(page) {
      this.currentPage = page;
      this.loadOrders();
    },
    perPageChange(num) {
      this.currentPage = 1;
      this.perPage = num;
      this.loadOrders();
    },
    onPageChange() {
      this.loadOrders();
    }
  },
};
</script>
