<template>
    <div class="edit-item">
      <b-modal
          v-model="maintenanceModalShow"
          :ok-title="$t('pdf.submitMaintenance')"
          @ok="maintenancePressed()"
          @hide="$emit('clearAction')"
      >
        <div>
          <b-form-group
              :label="$t('forms.notes')"
              label-for="notes"
              label-cols-md="4"
              content-cols-lg="8"
          >
            <b-form-textarea v-model="itemHistory.notes" id="notes" />
          </b-form-group>
        </div>
      </b-modal>

      <title-bar
            submitBtn
            :submitBtnTitle="submitBtnTitle"
            :title="title"
            @submitPressed="submitPressed"

            :thirdBtn="item && (item.itemCategory === 'TOOL' || item.itemCategory === 'EXTRACTION') ? true : false"
            :thirdBtnTitle="$t('pdf.printMarkingLabel')"
            @thirdPressed="printMarkingLabelPressed"

            :fourthBtn="item && (item.itemCategory === 'TOOL' || item.itemCategory === 'EXTRACTION') && this.getStripedCategoryName() !== 'applicator'? true : false"
            :fourthBtnTitle="$t('pdf.printBagLabel')"
            @fourthPressed="printBagLabelPressed"

            :fifthBtn="item && (item.itemCategory === 'TOOL' || item.itemCategory === 'EXTRACTION') ? true : false"
            :fifthBtnTitle="$t('pdf.printLocationLabel')"
            @fifthPressed="printLocationLabelPressed"

            :sixthBtn="item && item.itemCategory === 'LAY_BOARD' ? true : false"
            :sixthBtnTitle="$t('pdf.printLabel')"
            @sixthPressed="printLayboardLabelPressed"

            :secondaryBtn="item && item.itemCategory === 'TOOL' ? true : false"
            :secondaryBtnTitle="$t('pdf.maintenance')"
            @secondaryPressed="maintenanceModalShowPressed"
        />
        <items-form
            v-if="item"
            :baseUrl="baseUrl"
            :itemObject="item"
            :action="action"
            @clearAction="action = null"
        />
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import ItemsForm from "./ItemsForm.vue";
import Toast from "@/components/Toast.vue";
import {useToast} from "vue-toastification";
import moment from "moment";

export default {
    components: {
        TitleBar,
        ItemsForm,
    },
    props: {
        baseUrl: { type: String, required: false, default: '/items' },
        submitBtnTitle: { type: String, required: true },
        title: { type: String, required: true },
    },
    data() {
        return {
            item: null,
            action: null,
            printPdf: {
              templateName: null
            },
            itemHistory: {
              notes: null
            },
            pdfResponse: null,
            maintenanceModalShow: false
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const id = this.$route.params.id;
            this.$Items.getResource({ id }).then((response) => {
                this.item = response.data;
                this.getItemHistories()
                this.item.itemCategorySelect = {
                    label: this.$helper.getEnumTranslation("ITEM_CATEGORY", this.item.itemCategory, this.$i18n.locale),
                    id: this.item.itemCategory
                }
                this.item.statusSelect = {
                    label: this.$helper.getEnumTranslation("ITEM_STATUS", this.item.status, this.$i18n.locale),
                    id: this.item.status
                }
                this.item.itemConditionSelect = {
                    label: this.$helper.getEnumTranslation("ITEM_CONDITION", this.item.itemCondition, this.$i18n.locale),
                    id: this.item.itemCondition
                }
                if (this.item.category) {
                    this.item.categorySelect = {
                        label: this.item.category.name,
                        id: this.item.category['@id']
                    }
                }
                if (this.item.department) {
                    this.item.departmentSelect = {
                        label: this.item.department.name,
                        id: this.item.department['@id']
                    }
                }
                if (this.item.navisionType) {
                    this.item.navisionTypeSelect = {
                        label: this.item.navisionType.name,
                        id: this.item.navisionType['@id']
                    }
                }
                if (this.item.location) {
                    this.item.locationSelect = {
                        label: this.item.location.name,
                        id: this.item.location['@id']
                    }
                }
                if (this.item.type) {
                    this.item.typeSelect = {
                        label: this.item.type.name,
                        id: this.item.type['@id']
                    }
                }
                if (this.item.maintenanceType) {
                    this.item.maintenanceTypeSelect = {
                        label: this.item.maintenanceType.name,
                        id: this.item.maintenanceType['@id']
                    }
                }
                if (this.item.tool) {
                    this.item.toolSelect = this.$helper.getItemSelectOption(this.item.tool)
                }
                this.item.toolsSelect = []
                if (this.item.tools) {
                  this.item.tools.forEach((item) => {
                    this.item.toolsSelect.push(this.$helper.getItemSelectOption(item))
                  })
                }
                if (this.item.gromet) {
                    this.item.grometSelect = this.$helper.getItemSelectOption(this.item.gromet)
                }
                if (this.item.handtool) {
                    this.item.handtoolSelect = this.$helper.getItemSelectOption(this.item.handtool)
                }
                if (this.item.airpress) {
                    this.item.airpressSelect = this.$helper.getItemSelectOption(this.item.airpress)
                }
                if (this.item.applicator) {
                    this.item.applicatorSelect = this.$helper.getItemSelectOption(this.item.applicator)
                }
                if (this.item.applicator2) {
                    this.item.applicator2Select = this.$helper.getItemSelectOption(this.item.applicator2)
                }
                if (this.item.navisionItem) {
                    this.item.navisionItemSelect = this.$helper.getItemSelectOption(this.item.navisionItem)
                }

                if (this.item.size) {
                  this.item.sizeSelect = {
                    label: this.$helper.getEnumTranslation("ITEM_SIZE", this.item.size, this.$i18n.locale),
                    id: this.item.size
                  }
                }

                if (this.item.controlled) {
                  this.item.controlledSelect = {
                    label: this.$helper.getEnumTranslation("ITEM_CONTROLLED", this.item.controlled, this.$i18n.locale),
                    id: this.item.controlled
                  }
                }

                if (this.item.layboardType) {
                  this.item.layboardTypeSelect = {
                    label: this.$helper.getEnumTranslation("LAYBOARD_TYPE", this.item.layboardType, this.$i18n.locale),
                    id: this.item.layboardType
                  }
                }

                this.item.itemVendors = []
                if (this.item.vendors) {
                    this.item.vendors.forEach((itemVendor) => {
                      this.item.itemVendors.push({
                        item: this.item,
                        id: itemVendor.id,
                        price: itemVendor.price,
                        leadTime: itemVendor.leadTime,
                        vendorItemNumber: itemVendor.vendorItemNumber,
                        reqQuantity: parseInt(itemVendor.reqQuantity),
                        vendorSelect: {
                          label: itemVendor.vendor ? itemVendor.vendor.name : null,
                          id: itemVendor.vendor ? itemVendor.vendor['@id'] : null
                        }
                      })
                    })
                }

                if (this.item.user) {
                    this.item.userSelect = {
                        label: this.item.user.givenName+' '+this.item.user.familyName,
                        id: this.item.user['@id']
                    }
                }
            });
        },
        submitPressed() {
            this.action = "update";
        },
        getItemHistories() {
          this.$Items
              .getResourceByUrl({ url: `${this.item['@id']}/histories` }, 'item_histories')
              .then((response) => {
                if (response.status === 200) {
                  this.item.historiesDetails = response.data["hydra:member"]
                }
              });
        },
        getStripedCategoryName() {
          if (!this.item.categorySelect) {
            return null
          }

          return this.item.categorySelect.label.toLowerCase()
        },
        printMarkingLabelPressed() {
          this.printPdf.templateName = null
          if (this.getStripedCategoryName() === 'handtool') {
            this.printPdf.templateName = `HANDTOOL_MARKING_LABEL`
          } else if (this.getStripedCategoryName() === 'applicator') {
            this.printPdf.templateName = `APPLICATOR_MARKING_LABEL`
          } else if (this.item.itemCategory === 'EXTRACTION' || this.getStripedCategoryName() === 'extraction tool' || this.getStripedCategoryName() === 'extraction') {
            this.printPdf.templateName = `EXTRACTION_TOOL_MARKING_LABEL`
          }
          this.generateLabel()
        },
        printBagLabelPressed() {
          this.printPdf.templateName = null
          if (this.getStripedCategoryName() === 'handtool') {
            this.printPdf.templateName = `HANDTOOL_LOCATION_BAG_LABEL`
          } else if (this.item.itemCategory === 'EXTRACTION' || this.getStripedCategoryName() === 'extraction tool' || this.getStripedCategoryName() === 'extraction') {
            this.printPdf.templateName = `EXTRACTION_TOOL_BAG_LABEL`
          }
          this.generateLabel()
        },
        printLocationLabelPressed() {
          this.printPdf.templateName = null
          if (this.getStripedCategoryName() === 'handtool') {
            this.printPdf.templateName = `HANDTOOL_LOCATION_BAG_LABEL`
          } else if (this.getStripedCategoryName() === 'applicator') {
            this.printPdf.templateName = `APPLICATOR_LOCATION_LABEL`
          } else if (this.item.itemCategory === 'EXTRACTION' || this.getStripedCategoryName() === 'extraction tool' || this.getStripedCategoryName() === 'extraction') {
            this.printPdf.templateName = `EXTRACTION_TOOL_LOCATION_LABEL`
          }
          this.generateLabel()
        },
        printLayboardLabelPressed() {
          this.printPdf.templateName = null
          if (this.item.itemCategory === 'LAY_BOARD') {
            this.printPdf.templateName = `LAYBOARD_LABEL`
          }
          this.generateLabel()
        },
        maintenancePressed() {
          this.printPdf.templateName = `MAINTENANCE_LABEL`
          this.generateLabel()
        },
        generateLabel() {
          if (null === this.printPdf.templateName) {
            return this.$helper.showToast(
                useToast(),
                Toast,
                this.$t("pdf.incorrectCategory"),
                this.$t("pdf.allowedCategoryNames"),
                "danger"
            );
          }

          const body = {
            name: `${this.printPdf.templateName.toLowerCase()}_${this.item.id}_${moment().format('YYYY_MM_DD')}`,
            templateName: this.printPdf.templateName,
            resource: this.item["@id"]
          };

          this.$Pdfs
              .getResourceByUrl({ url: `/pdfs/${body.name}` })
              .then(() => {
                this.$helper.showToast(
                    useToast(),
                    Toast,
                    this.$t("pdf.labelAlreadyCreatedForToday"),
                    this.$t("pdf.labelCanFindInHistoryList"),
                    "success"
                );
              })
              .catch(() => {
                this.$Pdfs.createResource({ body }).then((response) => {
                  if (response.status === 201) {
                    this.pdfResponse = response.data
                    this.$helper.showToast(
                        useToast(),
                        Toast,
                        this.$t("pdf.labelCreated"),
                        this.$t("pdf.labelAddedToPrintList"),
                        "success"
                    );
                    this.generateItemHistory()

                    if (this.printPdf.templateName === 'MAINTENANCE_LABEL' && this.item.itemInterval) {
                      const lastDate = moment().toDate()
                      const nextDate = moment().add(this.item.itemInterval, 'days').toDate()
                      this.item.lastDate = lastDate
                      this.item.nextDate = nextDate
                    }
                  }
                });
              });
        },
        generateItemHistory() {
          const note = this.$helper.getEnumTranslation("PDF_TEMPLATE_NAME", this.printPdf.templateName, this.$i18n.locale) //this.printPdf.templateName.replaceAll('_', ' ').toLowerCase()

          const noteCapitalized = note[0].toUpperCase() + note.slice(1);
          const notes = [noteCapitalized]

          if (this.itemHistory.notes) {
            notes.push(this.itemHistory.notes)
          }

          const body = {
            item: this.item['@id'],
            notes: notes.join(', '),
          };

          if (this.pdfResponse) {
            body.pdf = this.pdfResponse['@id']
          }

          this.$ItemHistories.createResource({ body }).then((response) => {
            if (response.status === 201) {
              response.data.pdf = this.pdfResponse
              this.pdfResponse = null
              this.itemHistory.notes = null
              this.item.histories.push(response.data)
              this.$helper.showToast(
                  useToast(),
                  Toast,
                  this.$t("pdf.itemHistoryCreated"),
                  this.$t("pdf.itemHistoryAddedToItemHistory"),
                  "success"
              );
            }
          });
        },
        maintenanceModalShowPressed() {
          this.maintenanceModalShow = true
        }
    },
};
</script>
