<template>
  <b-button variant="gray" :pdfResource="pdfResource" @click="printPdf">
    {{ $t("pdf.printLabel") }}
  </b-button>
</template>

<script>

export default {
  name: 'PrintPdfBtn',
  props: {
    pdfResource: { type: String, required: true },
    pdfUrl: { type: String, required: true },
  },
  methods: {
    printPdf() {
      const body = {
        pdf: this.pdfResource
      };
      this.$PrintHistories.createResource({ body }).then((response) => {
        if (response.status === 201) {
          this.$emit("pdfPrinted", { pdfResource: this.pdfResource, printHistory: response.data })
          const link = document.createElement("a");
          link.href = this.pdfUrl;
          link.target = "_blank";
          link.click();
        }
      });
    }
  }
}
</script>
